import Tile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS.js'
import OSM from 'ol/source/OSM';
import BingMaps from 'ol/source/BingMaps';
import TileLayer from 'ol/layer/Tile.js'
import LayerGroup from 'ol/layer/Group.js'


/* 
TODO:
see:
https://osm-hr.org/2021/07/30/ortofoto-2020-i-mapproxy-hr/
https://github.com/osm-hr/mapproxy-hr
https://geoportal.dgu.hr/#/menu/moji-detalji
*/ 

const cadastre = new LayerGroup(
    {
        name: 'DGU KP',
        visible:false,
        layers: [
            /* new TileLayer({
                visible:false,
                source: new TileWMS({
                    url: 'https://geoportal.dgu.hr/services/inspire/orthophoto_2019_2020/ows',
                    params: {
                        'FORMAT': 'image/png',
                        'TRANSPARENT': 'TRUE',
                        'VERSION': '1.3.0',
                        'SERVICE': 'WMS',
                        'REQUEST': 'GetMap',
                        'LAYERS': 'OI.OrthoimageCoverage',
                        'STYLES': '',
                    },
                    serverType: 'geoserver', 
                    //crossOrigin: 'anonymous', // Important for CORS, if needed
                })
            })
            */
            new TileLayer({
                name: 'DGU DOF',
                baseLayer: true,
                source: new TileWMS({
                    url:
                        'https://gis.edc.hr/geoserver/dofWMS/ows?SERVICE=WMS',
                    params: { LAYERS: 'dofWMS:DGU_DOF_2021_2022', TILED: true },
                    crossOrigin: 'anonymous'
                })
            }),
            new TileLayer({
                visible:false,
                minZoom: 14,
                source: new TileWMS({
                    url:
                        'https://api.uredjenazemlja.hr/services/inspire/cp_wms/ows?',
                    params: { 'layers': 'cp:CP.CadastralZoning' },
                    crossOrigin: 'anonymous',
                }),
            }),
            new TileLayer({ 
                visible:false,
                minZoom: 14,
                source: new TileWMS({
                    url:
                        'https://api.uredjenazemlja.hr/services/inspire/cp_wms/ows?',
                    params: { 'layers': 'cp:CP.CadastralParcel' },
                    crossOrigin: 'anonymous'
                })
            }),
           
        ]
    })
export function getTiles() {
    return [
        new LayerGroup({
            name: 'tiles',
            layers: [
                new Tile({
                    name: 'OSM',
                    permalink: 'osm',
                    cacheSize: 2048,
                    visible: true,
                    source: new OSM()
                }),
                new Tile({
                    name: 'Bing maps',
                    permalink: 'bing',
                    visible: false,
                    source: new BingMaps({
                        placeholderTiles: false,
                        key: 'Asy8J51JJ7llyF3k35bhsNUUXZpJ1Sx2XM1AVrVlm1fffX_V-k68gi8VtjOrM68b',
                        imagerySet: 'Aerial',
                    })
                }),
                new Tile({
                    name: 'DGU DOF',
                    permalink: 'dof',
                    visible: false,
                    source: new TileWMS({
                        url:
                            'https://gis.edc.hr/geoserver/dofWMS/ows?SERVICE=WMS',
                        params: { LAYERS: 'dofWMS:DGU_DOF_2021_2022', TILED: true },
                        crossOrigin: 'anonymous'
                    }),
                }),
                cadastre
            ]
        })
    ]
}