import locale from "../lang/hr.json";
import Control from "ol/control/Control";
import { Modal } from "bootstrap";
import Map from "ol/Map.js";
import View from "ol/View.js";
import { getTiles } from "./tiles.js";
import VectorLayer from "ol/layer/Vector.js";
import VectorSource from "ol/source/Vector.js";
import LayerGroup from "ol/layer/Group.js";

export class PrintDialog extends Control {
  constructor(options = {}) {
    const element = document.createElement("div");
    element.className = "modal print-modal";
    element.innerHTML = `
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content"></div>`;
    super({
      target: document.body,
      element,
    });

    this.modal = new Modal(element);
    this.content = this.element.querySelector(".modal-content");
    this.content.innerHTML = `<div class="modal-body row p-0">
        <!--- Dialog  --->
        <div class="col-12 col-md-3 p-4 bg-light">
            <div class="d-flex justify-content-between align-items-center mb-3">
            <h5 class="m-0">${locale.printTitle}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="d-flex justify-content-evenly align-items-center my-4 orientation">
            <button type="button" class="btn btn-primary text-center active portrait" style="width:100px">
                <i class="fa-regular fa-image-portrait fa-xl"></i>
                <div>${locale.printPortrait}</div>
            </button>
            <button type="button" class="btn btn-primary text-center landscape" style="width:100px">
                <i class="fa-regular fa-image-landscape fa-xl"></i>
                <div>${locale.printLandscape}</div>
            </button>
            </div>
            <div class="form-floating mb-3">
            <select class="form-select" id="print-format-select">
                <option value="A0">A0 - 841x1189 mm</option>
                <option value="A1">A1 - 594x841 mm</option>
                <option value="A2">A2 - 420x594 mm</option>
                <option value="A3">A3 - 297x420 mm</option>
                <option selected value="A4">A4 - 210x297 mm</option>
                <option value="A5">A5 - 148x210 mm</option>
            </select>
            <label for="print-dim-select">${locale.printPageSize}</label>
            </div>
            <div class="alert alert-primary print-format-info d-none">
              ${locale.printFormatInfo}
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
            <label>${locale.printMargins}</label>
            <select class="form-select w-auto" id="print-margin-select">
                <option value="0">${locale.printMarginNone} - 0 mm</option>
                <option selected value="5">${locale.printMarginSmall} - 5 mm</option>
                <option value="10">${locale.printMarginLarge} - 10 mm</option>
            </select>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
            <label>${locale.printScale} 1 :</label>
            <input type="number" class="form-control w-auto" id="print-scale-input" 
            placeholder=${locale.printScalePlaceholder} min="500" step="500" value="5000" list="print-scale-options">
            <datalist id="print-scale-options">
                <option value="500">
                <option value="1000">
                <option value="2500">
                <option value="5000">
                <option value="10000">
                <option value="25000">
                <option value="50000">
                <option value="100000">
                <option value="250000">
                <option value="1000000">
            </datalist>
            </div>
            <div class="form-floating mb-3">
                <select class="form-select" id="print-dpi-select">
                    <option value="96"  selected>${locale.printDpiScreen}</option>
                    <option value="200">${locale.printDpiDoubleScreen}</option>
                    <option value="300">${locale.printDpiPrint}</option>
                </select>
                <label for="print-dpi-select">${locale.printDpi}</label>
            </div>
            <div class="col-12 d-flex justify-content-end align-items-center">
            <div class="dropdown">
                <span class="print-spinner spinner-grow spinner-grow-sm text-primary d-none"></span>
                <button class="btn btn-primary dropdown-toggle" type="button" id="print-dropdown-options" data-bs-toggle="dropdown">
                ${locale.printSaveAs}...
                </button>
               
                <div class="dropdown-menu">
                <button class="dropdown-item save-pdf">
                    <i class="fa-regular fa-file-pdf"></i>
                    ${locale.printSaveToPdf}</button>
                <button class="dropdown-item save-png">
                    <i class="fa-regular fa-file-png"></i>
                    ${locale.printSaveToPng}</button>
                <button class="dropdown-item save-jpeg">
                    <i class="fa-regular fa-file-jpg"></i>
                    ${locale.printSaveToJpeg}</button>
                </div>
            </div>
            </div>
        </div>
        <!--- Map   --->
        <div class="col-12 col-md-9 d-flex justify-content-center align-items-center right">
            <div id="print-map" class='border border-secondary border-2'>
            </div>
        </div>
            </div>`;
    this.right = this.content.querySelector(".right");
    this.target = this.content.querySelector("#print-map");
    this.portrait = this.content.querySelector(".portrait");
    this.landscape = this.content.querySelector(".landscape");
    this.scale = this.content.querySelector("#print-scale-input");
    this.format = this.content.querySelector("#print-format-select");
    this.formatInfo = this.content.querySelector(".print-format-info");
    this.margin = this.content.querySelector("#print-margin-select");
    this.png = this.content.querySelector(".save-png");
    this.jpeg = this.content.querySelector(".save-jpeg");
    this.pdf = this.content.querySelector(".save-pdf");
    this.printDpi = this.content.querySelector("#print-dpi-select");
    this.printSpinner = this.content.querySelector(".print-spinner");
    this.printOptions = this.content.querySelector("#print-dropdown-options");

    this.handlePortrait = this.handlePortrait.bind(this);
    this.handleLandscape = this.handleLandscape.bind(this);
    this.handleScale = this.handleScale.bind(this);
    this.handleResolution = this.handleResolution.bind(this);
    this.handleFormat = this.handleFormat.bind(this);
    this.handleMargin = this.handleMargin.bind(this);
    this.handlePdf = this.handlePdf.bind(this);
    this.handlePng = this.handlePng.bind(this);
    this.handleJpeg = this.handleJpeg.bind(this);

    this.portrait.addEventListener("click", this.handlePortrait);
    this.landscape.addEventListener("click", this.handleLandscape);
    this.scale.addEventListener("change", this.handleScale);
    this.format.addEventListener("change", this.handleFormat);
    this.margin.addEventListener("change", this.handleMargin);
    this.png.addEventListener("click", this.handlePng);
    this.jpeg.addEventListener("click", this.handleJpeg);
    this.pdf.addEventListener("click", this.handlePdf);

    this.dpi = 96; // screen res
    this.inchesPerMeter = 39.3701;
    this.oldFormatValue = this.format.value;
    this.callback = options.callback; // function to call on print-dropdown-options click
    // if undefined uses own printing
    element.addEventListener("shown.bs.modal", () => {
      this.updateTargetSize();
      this.generatePrintMap();
      this.handleResolution();
      this.printMap.getView().on("change:resolution", this.handleResolution);
      this.handleMargin();
    });

    element.addEventListener("hidden.bs.modal", () => {
      if (this.printMap) {
        this.printMap.setTarget(null);
      }
    });
  }
  handlePdf() {
    const printParameters = this.printParameters();
    printParameters.contentType = "application/pdf";
    if (this.callback) {
      this.callback(printParameters);
      return;
    }
  }
  handleJpeg() {
    const printParameters = this.printParameters();
    printParameters.contentType = "image/jpeg";
    if (this.callback) {
      this.callback(printParameters);
      return;
    }
  }
  handlePng() {
    const printParameters = this.printParameters();
    printParameters.contentType = "image/png";
    if (this.callback) {
      this.callback(printParameters);
      return;
    }
  }
  handleMargin() {
    const margin = this.margin.value;
    let size = this.formatDimensions(this.format.value);
    if (this.landscape.classList.contains("active")) {
      const height = size.width;
      const width = size.height;
      size.height = height;
      size.width = width;
    }
    const target = this.printMap.getTarget();
    const mapWidth = target.offsetWidth;
    const mapHeight = target.offsetHeight;
    const padding = this.calculatePadding(
      size.width,
      size.height,
      mapWidth,
      mapHeight,
      margin
    );
    this.target.style.padding = `${padding.paddingTopBottom}px ${padding.paddingLeftRight}px`;
  }
  handleFormat() {
    const currResolution = this.printMap.getView().getResolution();
    const resolution = this.calculateResolution(
      this.oldFormatValue,
      this.format.value,
      currResolution
    );
    this.printMap.getView().un("change:resolution", this.handleResolution);
    this.printMap.getView().setResolution(resolution);
    this.printMap.getView().on("change:resolution", this.handleResolution);
    this.oldFormatValue = this.format.value;
    if (this.format.value.at(-1) <= 2) {
      this.formatInfo.classList.remove("d-none");
    } else this.formatInfo.classList.add("d-none");
  }
  handleScale() {
    this.printMap
      .getView()
      .setResolution(this.scale.value / (this.dpi * this.inchesPerMeter));
    this.printMap.updateSize();
  }
  handleResolution() {
    this.scale.value = Math.round(
      this.printMap.getView().getResolution() * this.inchesPerMeter * this.dpi
    );
  }
  handlePortrait() {
    this.portrait.classList.add("active");
    this.landscape.classList.remove("active");
    this.updateTargetSize();
  }
  handleLandscape() {
    this.landscape.classList.add("active");
    this.portrait.classList.remove("active");
    this.updateTargetSize();
  }
  setMap(map) {
    super.setMap(map);
    this.map = map;
  }
  printParameters() {
    const resolution = this.calculateResolution(
      this.format.value,
      "A4",
      this.printMap.getView().getResolution()
    );
    const orientation = this.portrait.classList.contains("active")
      ? "portrait"
      : "landscape";
    const f = this.formatDimensions(this.format.value);
    let formatDim = { width: f.width, height: f.height };
    if (orientation === "landscape") {
      formatDim = { width: f.height, height: f.width };
    }
    return {
      map: this.printMap,
      bbox: this.printMap.getView().calculateExtent(),
      orientation,
      format: this.format.value,
      formatDim,
      margin: this.margin.value,
      scale: this.scale.value,
      dpi: this.printDpi.value,
      resolution, // map resolution for A4 format is default
    };
  }
  calculatePadding(paperWidth, paperHeight, mapWidth, mapHeight, marginInMm) {
    const marginPercentageWidth = (marginInMm / paperWidth) * 100;
    const marginPercentageHeight = (marginInMm / paperHeight) * 100;
    const paddingHorizontal = (mapWidth * marginPercentageWidth) / 100;
    const paddingVertical = (mapHeight * marginPercentageHeight) / 100;
    return {
      paddingTopBottom: paddingVertical,
      paddingLeftRight: paddingHorizontal,
    };
  }
  generatePrintMap() {
    const tileLayers = this.map
      .getLayers()
      .getArray()
      .find((x) => x instanceof LayerGroup && x.get("name") === "tiles");
    const visibleTileLayer = tileLayers
      .getLayers()
      .getArray()
      .find((x) => x.getVisible());
    const newLayers = getTiles();
    newLayers[0]
      .getLayers()
      .getArray()
      .forEach((x) =>
        x.setVisible(x.get("name") === visibleTileLayer.get("name"))
      );
    newLayers[0]
      .getLayers()
      .getArray()
      .find((x) => x.get("name") === visibleTileLayer.get("name"))
      .setVisible(true);
    const vectorLayers = this.map
      .getAllLayers()
      .filter((x) => x instanceof VectorLayer);
    vectorLayers.forEach((x) => {
      const features = x.getSource().getFeatures();
      const style = x.getStyle();
      const visible = x.getVisible();
      newLayers.push(
        new VectorLayer({
          source: new VectorSource({ features }),
          style,
          visible,
        })
      );
    });
    this.printMap = new Map({
      target: this.target,
      view: new View({
        projection: this.map.getView().getProjection(),
        resolution: this.map.getView().getResolution(),
        rotation: this.map.getView().getRotation(),
        center: this.map.getView().getCenter(),
      }),
      controls: [],
      layers: newLayers,
    });
  }
  formatDimensions(format) {
    const paperFormats = [
      { format: "A0", width: 841, height: 1189 },
      { format: "A1", width: 594, height: 841 },
      { format: "A2", width: 420, height: 594 },
      { format: "A3", width: 297, height: 420 },
      { format: "A4", width: 210, height: 297 },
      { format: "A5", width: 148, height: 210 },
    ];
    return paperFormats.find((x) => x.format === format);
  }
  calculateResolution(fromFormat, toFormat, currentResolution) {
    const sqrt2 = Math.sqrt(2);
    const fromNumber = parseInt(fromFormat.slice(1));
    const toNumber = parseInt(toFormat.slice(1));
    const difference = toNumber - fromNumber;
    let newResolution = currentResolution;
    if (difference > 0) {
      newResolution /= Math.pow(sqrt2, Math.abs(difference));
    } else if (difference < 0) {
      newResolution *= Math.pow(sqrt2, Math.abs(difference));
    }
    return newResolution;
  }
  updateTargetSize() {
    const viewportHeight = this.right.clientHeight * 0.9;
    const viewportWidth = this.right.clientWidth * 0.9;
    let height, width;
    if (this.portrait.classList.contains("active")) {
      height = viewportHeight;
      width = height * 0.707; // sqrt(2) 'A' norm
      if (width > viewportWidth) {
        width = viewportWidth;
        height = width / 0.707;
      }
    }
    if (this.landscape.classList.contains("active")) {
      width = viewportWidth;
      height = width * 0.707;
      if (height > viewportHeight) {
        height = viewportHeight;
        width = height / 0.707;
      }
    }
    this.target.style.height = height + "px";
    this.target.style.width = width + "px";
  }
  toggle() {
    this.modal.toggle();
  }
}
